/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */
/* eslint-disable hubspot-dev/no-confusing-browser-globals */

(function () {
  let ZORSE_WIDGET_QS = 'zorseWidget';
  if (window.location && window.location.search && window.location.search.indexOf(ZORSE_WIDGET_QS) > 0) {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'ready'.
    ready = function (callback) {
      if (document.readyState !== 'loading') {
        return callback();
      } else {
        return document.addEventListener('DOMContentLoaded', callback);
      }
    };

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'ready'.
    ready(function () {
      if (parent && parent.postMessage) {
        parent.postMessage('ready', '*');
      }
      let head = document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0];
      if (head) {
        let customZorseStyle = 'body { font-family: "Lexend Deca", Helvetica, Arial, sans-serif !important; font-weight: 400 !important; } .cta_button, .header-container-wrapper, .footer-container-wrapper, #help-cta, .hs-cta-wrapper, #login_cta, .primary-header, #new-tally-button, #tally-widget-container, .toc-button, #st-search-input, [data-global-resource-path="Knowledge/Templates/Partials/kb-header.html"], [data-global-resource-path="Knowledge/Templates/Partials/kb-footer.html"], section.breadcrumb-lang-picker, .sidebar-container { display: none !important; }  h1:first-child { margin-top: 1.25rem; }';
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'style'.
        style = document.createElement('style');
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'style'.
        style.appendChild(document.createTextNode(customZorseStyle));
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'style'.
        head.appendChild(style);
      }
      let links = document.getElementsByTagName('a');
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'i'.
      for (i = 0; i < links.length; i++) {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'i'.
        let link = links[i];
        if (link.href && link.href.indexOf('hubspot.com') !== -1 && link.href.indexOf(ZORSE_WIDGET_QS) === -1) {
          let separator = link.href.indexOf('?') > 0 ? '&' : '?';
          link.href = link.href + separator + ZORSE_WIDGET_QS;
          link.target = '';
        } else {
          link.target = '_blank';
        }
      }
      return this;
    });
  }
})();

// removing this line results in the ts(1208) isolatedModules error